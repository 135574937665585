<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/cron`" title="Cron" :columns="columns"
                routerpath="/setup/addcron" :formOptions="formOptions"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Cron' : 'Add Cron'}`,
        submitRouterPath: '/setup/cron',
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/getcronById`,
        url: `${baseApi}/cron`,
        gridForm: true,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Minutes',
            name: 'minute',
            value: '',
            type: 'number',
            placeholder: 'Enter Minutes',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Hours',
            name: 'hour',
            value: '',
            type: 'number',
            placeholder: 'Enter Hours',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Day',
            name: 'day',
            value: '',
            type: 'text',
            placeholder: 'Enter Day',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Month',
            name: 'month',
            value: '',
            type: 'text',
            placeholder: 'Enter Month',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Year',
            name: 'year',
            value: '',
            type: 'number',
            placeholder: 'Enter Year',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Command',
            name: 'Command',
            value: '',
            type: 'text',
            placeholder: 'Enter Command',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Remarks',
            name: 'remarks',
            value: '',
            type: 'text',
            placeholder: 'Enter Remarks',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Email',
            class: 'col-md-6',
            required: true
          }
        ]
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          },
          hidden: true
        },
        {
          label: 'Minutes',
          field: 'minute',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Minutes'
          }
        },
        {
          label: 'Hours',
          field: 'hour',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Hours'
          }
        },
        {
          label: 'Day',
          field: 'day',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Day'
          }
        },
        {
          label: 'Month',
          field: 'month',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Month'
          }
        },
        {
          label: 'Year',
          field: 'year',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Year'
          }
        },
        {
          label: 'Command',
          field: 'command',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Command'
          }
        },
        {
          label: 'Remarks',
          field: 'remark',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Remarks'
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ]
    }
  },
  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  },

  beforeMount () {
    
    // this.getDropdowns()
  },
  methods: {
    async getDropdowns () {
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, 'error'))
        }
      })

      this.assignToFormOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
